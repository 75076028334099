var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],style:(_vm.getStyle),attrs:{"element-loading-text":_vm.loadingText}},[_c('el-row',{attrs:{"align":"middle"}},[_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 14 : 18}},[_c('label',{attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.data.label))]),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e()]),_c('el-col',{attrs:{"span":6}},[(_vm.allRowsData.length > _vm.limit)?_c('div',{staticClass:"d-flex"},[_c('el-input',{staticClass:"mr-1",attrs:{"placeholder":"Search","size":"mini","clearable":""},on:{"input":_vm.updatePagination},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-arrow-left","size":"mini","disabled":_vm.page <= 1},on:{"click":function($event){return _vm.pageChange('BACKWARD')}}}),_c('span',{staticClass:"ml-1 mr-1"},[_vm._v(_vm._s(_vm.page))]),_c('el-button',{attrs:{"type":"primary","icon":"el-icon-arrow-right","size":"mini","disabled":(_vm.allRowsData.length || 0) <= _vm.page * _vm.limit},on:{"click":function($event){return _vm.pageChange('FORWARD')}}})],1):_vm._e()])],1),_c('span',[(_vm.data.card_view)?_c('el-button',{attrs:{"size":"mini","icon":"el-icon-plus","disabled":_vm.checkEntityVariable('ADD')
      ? _vm.checkEntityVariable('ADD')
        : _vm.readonly()},on:{"click":function($event){return _vm.addNewRow(-1)}}}):_vm._e()],1),(_vm.isActive || _vm.isDataTableField)?_c('span',{staticClass:"setting-icon",on:{"click":_vm.openSettings}},[_c('i',{staticClass:"el-icon-s-tools"})]):_vm._e(),(!_vm.data.card_view)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.dataRefresh),expression:"dataRefresh"}],staticClass:"table-container"},[_c('el-scrollbar',{staticClass:"scrollbar"},[_c('table',{staticClass:"content-table",staticStyle:{"width":"-webkit-fill-available"}},[_c('tr',[(!_vm.isView && !_vm.isDetailedView && _vm.isShowSelectColumns())?_c('th',{staticClass:"sticky select-check"},[_c('el-checkbox',{attrs:{"indeterminate":_vm.isIndeterminate},on:{"change":_vm.handleCheckAllChange},model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}})],1):_vm._e(),_vm._l((_vm.removeHideFields(
              _vm.data.data_table_columns
            )),function(column,index){return _c('th',{key:index,style:(_vm.getColumnStyle(column))},[_vm._v(" "+_vm._s(column.label)+" "),_c('span',[(column.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":column.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1),(!_vm.isView && column.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-left":"5px","margin-right":"5px"}},[_vm._v("*")]):_vm._e()])}),(!_vm.isView && !_vm.isDetailedView)?_c('th',{staticClass:"sticky"},[_c('el-tooltip',{attrs:{"content":'Add ' + _vm.data.label}},[_c('el-button',{staticClass:"p-1",attrs:{"size":"mini","icon":"el-icon-plus","type":"primary","disabled":_vm.checkEntityVariable('ADD')
                  ? _vm.checkEntityVariable('ADD')
                  : _vm.readonly()},on:{"click":function($event){return _vm.addNewRow(-1)}}})],1)],1):_vm._e()],2),_vm._l((_vm.getRowsData),function(rowData,rowIndex){return _c('tr',{key:rowIndex +
            'data_table' +
            (rowData && rowData.form && rowData.form.keyIndex
              ? rowData.form.keyIndex
              : ''),ref:"newlyAddedRow",refInFor:true},[(!_vm.isView && !_vm.isDetailedView &&  _vm.isShowSelectColumns())?_c('th',{staticClass:"sticky select-check"},[_c('div',{staticClass:"d-flex"},[_c('el-checkbox-group',{on:{"change":_vm.handleCheckedRowChange},model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},[_c('el-checkbox',{attrs:{"label":rowIndex}})],1)],1)]):_vm._e(),_vm._l((_vm.removeHideFields(rowData.fields)),function(column,colIndex){return _c('td',{key:colIndex + '_main_fields'},[(!_vm.checkhideFields(column.key, rowIndex))?_c(column.components.execute,{tag:"component",attrs:{"data":column,"isInDataTable":true,"form":rowData.form,"fieldsData":_vm.fieldsData,"is-view":_vm.isGetView(column, rowData.form),"dataTableRowIndex":rowIndex,"parent-form-data":_vm.form,"autoFillEntityData":_vm.getAutoFillEntityData(column, rowData.form),"readonly":_vm.readonly(rowIndex),"rowsData":_vm.rowsData,"checkIsDisabled":_vm.checkIsDisabled(column.key, rowIndex)||_vm.checkSplitFieldReadOnly(column,rowData.form.keyIndex) || _vm.checkAllowEdit(rowData.form,rowIndex),"dataTableField":_vm.data},on:{"entityDataUpdated":_vm.setDataToEntityVariables,"clearEntityFields":_vm.unsetEntityVariables,"UIUpdated":_vm.updateParentUI,"applyFormRules":function($event){return _vm.rulesEventEmitter(
                  rowData.fields,
                  rowIndex,
                  rowData.form.keyIndex,
                  column.key
                )}}}):_vm._e()],1)}),_vm._l((_vm.hiddenFields(rowData.fields)),function(column,colIndex){return _c('td',{directives:[{name:"show",rawName:"v-show",value:(_vm.tempHideFields),expression:"tempHideFields"}],key:colIndex + '_hidden_fields'},[(!_vm.checkhideFields(column.key, rowIndex))?_c(column.components.execute,{tag:"component",attrs:{"data":column,"form":rowData.form,"fieldsData":_vm.fieldsData,"is-view":_vm.isGetView(column, rowData.form),"dataTableRowIndex":rowIndex,"parent-form-data":_vm.form,"autoFillEntityData":_vm.getAutoFillEntityData(column, rowData.form),"readonly":_vm.readonly(rowIndex),"rowsData":_vm.rowsData,"checkIsDisabled":_vm.checkIsDisabled(column.key, rowIndex)||_vm.checkSplitFieldReadOnly(column,rowData.form.keyIndex),"dataTableField":_vm.data},on:{"entityDataUpdated":_vm.setDataToEntityVariables,"clearEntityFields":_vm.unsetEntityVariables,"UIUpdated":_vm.updateParentUI,"applyFormRules":function($event){return _vm.rulesEventEmitter(
                    rowData.fields,
                    rowIndex,
                    rowData.form.keyIndex,
                    column.key
                  )}}}):_vm._e()],1)}),(!_vm.isView && !_vm.isDetailedView)?_c('th',{staticClass:"sticky"},[_c('div',{staticClass:"d-flex"},[_c('el-tooltip',{attrs:{"content":'Remove ' + _vm.data.label}},[_c('el-button',{staticClass:"p-1",attrs:{"size":"mini","icon":"el-icon-minus","disabled":_vm.checkEntityVariable('EDIT')
                    ? _vm.checkEntityVariable('EDIT')
                    : _vm.readonly()},on:{"click":function($event){return _vm.removeRow(rowIndex)}}})],1),_c('el-tooltip',{attrs:{"content":'Add ' + _vm.data.label}},[(_vm.data.is_entity_variable)?_c('el-button',{staticClass:"p-1",attrs:{"size":"mini","icon":"el-icon-plus","disabled":_vm.checkEntityVariable('EDIT')
                    ? _vm.checkEntityVariable('EDIT')
                    : _vm.readonly()},on:{"click":function($event){return _vm.splitRow(rowIndex)}}}):_vm._e()],1)],1)]):_vm._e()],2)})],2)])],1):_vm._e(),(_vm.data.card_view)?_c('div',{staticClass:"table-container"},[_c('el-scrollbar',{staticClass:"scrollbar"},[_c('div',{staticClass:"fixed-table",staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-evenly","flex-wrap":"wrap","scrollbar-width":"none"}},_vm._l((_vm.getRowsData),function(rowData,rowIndex){return _c('div',{key:rowIndex +
              'data_table' +
              (rowData && rowData.form && rowData.form.keyIndex
                ? rowData.form.keyIndex
                : ''),ref:"newlyAddedRow",refInFor:true,staticClass:"card-style"},[_c('div',{staticClass:"aligned-buttons"},[_c('el-button',{attrs:{"size":"mini","icon":"el-icon-plus","disabled":_vm.checkEntityVariable('ADD')
                  ? _vm.checkEntityVariable('ADD')
                  : _vm.readonly()},on:{"click":function($event){return _vm.addNewRow(-1)}}}),_c('el-button',{attrs:{"size":"mini","icon":"el-icon-minus","disabled":_vm.checkEntityVariable('EDIT')
                      ? _vm.checkEntityVariable('EDIT')
                      : _vm.readonly()},on:{"click":function($event){return _vm.removeRow(rowIndex)}}})],1),_vm._l((_vm.removeHideFields(rowData.fields)),function(column,colIndex){return _c('div',{key:colIndex + '_main_fields',style:({ width: column.width + 'px', margin: '10px' })},[(!_vm.checkhideFields(column.key, rowIndex))?_c(column.components.execute,{tag:"component",attrs:{"data":column,"isInDataTable":true,"form":rowData.form,"fieldsData":_vm.fieldsData,"is-view":_vm.isGetView(column, rowData.form),"dataTableRowIndex":rowIndex,"parent-form-data":_vm.form,"autoFillEntityData":_vm.getAutoFillEntityData(column, rowData.form),"readonly":_vm.readonly(rowIndex),"rowsData":_vm.rowsData,"checkIsDisabled":_vm.checkIsDisabled(column.key, rowIndex)||_vm.checkSplitFieldReadOnly(column,rowData.form.keyIndex),"dataTableField":_vm.data},on:{"entityDataUpdated":_vm.setDataToEntityVariables,"clearEntityFields":_vm.unsetEntityVariables,"UIUpdated":_vm.updateParentUI,"applyFormRules":function($event){return _vm.rulesEventEmitter(
                    rowData.fields,
                    rowIndex,
                    rowData.form.keyIndex,
                    column.key
                  )}}}):_vm._e()],1)}),_vm._l((_vm.hiddenFields(rowData.fields)),function(column,colIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tempHideFields),expression:"tempHideFields"}],key:colIndex + '_hidden_fields',style:({ width: column.width + 'px' })},[(!_vm.checkhideFields(column.key, rowIndex))?_c(column.components.execute,{tag:"component",attrs:{"data":column,"form":rowData.form,"fieldsData":_vm.fieldsData,"is-view":_vm.isGetView(column, rowData.form),"dataTableRowIndex":rowIndex,"parent-form-data":_vm.form,"autoFillEntityData":_vm.getAutoFillEntityData(column, rowData.form),"readonly":_vm.readonly(rowIndex),"rowsData":_vm.rowsData,"checkIsDisabled":_vm.checkIsDisabled(column.key, rowIndex)||_vm.checkSplitFieldReadOnly(column,rowData.form.keyIndex),"dataTableField":_vm.data},on:{"entityDataUpdated":_vm.setDataToEntityVariables,"clearEntityFields":_vm.unsetEntityVariables,"UIUpdated":_vm.updateParentUI,"applyFormRules":function($event){return _vm.rulesEventEmitter(
                      rowData.fields,
                      rowIndex,
                      rowData.form.keyIndex,
                      column.key
                    )}}}):_vm._e()],1)})],2)}),0)])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }